import React from 'react';
import '../../assets/css/WelcomeStyles/Welcome.css';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: require('../../assets/images/Home/RB_Logo_Vertical.png'),
    };
  }

  componentDidMount() {
    setTimeout(this.goToHome, 3000);
  }
  componentWillUnmount() {
    clearInterval(this.goToHome);
  }

  goToHome = () => {
    this.props.history.push('/home');
  };

  render() {
    return (
      <div className='Welcome-main-container'>
        <img
          src={this.state.logo}
          alt='welcome'
          id='RB-Logo'
          loading='lazy'
          style={{ objectFit: 'cover' }}
        />
      </div>
    );
  }
}
export default Home;
