import React from "react";

import "./App.css";
import Routes from "./core/routes/index";

function App() {
  return <Routes />;
}

export default App;
