import React from "react";

const Loading = ({ color, width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 224 224"
    style={{ enableBackground: "new 0 0 224 224" }}
    width="100"
    height="70"
  >
    <g transform="rotate(0 50 50)">
      <path
        className="Li0"
        d="M112.6,14.8h2.5c5.1,0,9.2,4.1,9.2,9.2v27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2V24
       C103.4,18.9,107.5,14.8,112.6,14.8z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-1.1806375442739079s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(32.72727272727273 50 50)">
      <path
        className="Li0"
        d="M136.5-29.7h2.5c5.1,0,9.2,4.1,9.2,9.2l0,27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2v-27.6
       C127.3-25.6,131.4-29.7,136.5-29.7z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-1.062573789846517s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(65.45454545454545 50 50)">
      <path
        className="Li0"
        d="M132.6-80.1h2.5c5.1,0,9.2,4.1,9.2,9.2v27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2v-27.6
       C123.4-76,127.5-80.1,132.6-80.1z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-0.9445100354191263s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(98.18181818181819 50 50)">
      <path
        className="Li0"
        d="M102-120.4h2.5c5.1,0,9.2,4.1,9.2,9.2l0,27.6c0,5.1-4.1,9.2-9.2,9.2H102c-5.1,0-9.2-4.1-9.2-9.2l0-27.6
       C92.8-116.3,97-120.4,102-120.4z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-0.8264462809917354s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(130.9090909090909 50 50)">
      <path
        className="Li0"
        d="M54.6-137.7h2.5c5.1,0,9.2,4.1,9.2,9.2v27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2v-27.6
       C45.4-133.6,49.5-137.7,54.6-137.7z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-0.7083825265643446s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(163.63636363636363 50 50)">
      <path
        className="Li0"
        d="M5.3-126.7l2.5,0c5.1,0,9.2,4.1,9.2,9.2l0,27.6c0,5.1-4.1,9.2-9.2,9.2H5.3c-5.1,0-9.2-4.1-9.2-9.2l0-27.6
       C-3.9-122.6,0.2-126.7,5.3-126.7z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-0.5903187721369539s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(196.36363636363637 50 50)">
      <path
        className="Li0"
        d="M-30.2-90.7h2.5c5.1,0,9.2,4.1,9.2,9.2l0,27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2l0-27.6
       C-39.4-86.6-35.3-90.7-30.2-90.7z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-0.47225501770956313s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(229.0909090909091 50 50)">
      <path
        className="Li0"
        d="M-40.7-41.3h2.5c5.1,0,9.2,4.1,9.2,9.2l0,27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2v-27.6
       C-49.9-37.1-45.8-41.3-40.7-41.3z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-0.3541912632821723s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(261.8181818181818 50 50)">
      <path
        className="Li0"
        d="M-22.7,6l2.5,0c5.1,0,9.2,4.1,9.2,9.2l0,27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2l0-27.6
       C-31.9,10.1-27.8,6-22.7,6z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-0.23612750885478156s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(294.54545454545456 50 50)">
      <path
        className="Li0"
        d="M17.9,36h2.5c5.1,0,9.2,4.1,9.2,9.2l0,27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2l0-27.6
       C8.7,40.1,12.9,36,17.9,36z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="-0.11806375442739078s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
    <g transform="rotate(327.27272727272725 50 50)">
      <path
        className="Li0"
        d="M68.4,39.3h2.5c5.1,0,9.2,4.1,9.2,9.2v27.6c0,5.1-4.1,9.2-9.2,9.2h-2.5c-5.1,0-9.2-4.1-9.2-9.2l0-27.6
       C59.2,43.4,63.3,39.3,68.4,39.3z	"
      >
        <animate
          accumulate="none"
          additive="replace"
          attributeName="opacity"
          begin="0s"
          calcMode="linear"
          dur="2.2987012987012987s"
          fill="remove"
          keyTimes="0;1"
          repeatCount="indefinite"
          restart="always"
          values="1;0"
        ></animate>
      </path>
    </g>
  </svg>
);
export default Loading;
