import React, { Suspense, lazy } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import MySnackbar from "../../component/Snackbar/snackbar";
import LoadingPage from "../../component/LoadingPage/SimpleLoader";
import Welcome from "../../views/WelcomePage/Welcome";

const history = createBrowserHistory();
history.listen((_) => {
  window.scrollTo(0, 0);
});

const HomePage = lazy(() => import("../../views/Home/homePage"));
const About = lazy(() => import("../../views/About/aboutRb"));
const Services = lazy(() => import("../../views/Services/servicesRb"));
const ContactUs = lazy(() => import("../../views/ContactUs/contactRb"));
const Technology = lazy(() => import("../../views/Technology/Technology"));
const Careers = lazy(() => import("../../views/Careers/careersRb"));
const PrivacyPolicy = lazy(() =>
  import("../../views/PrivacyPolicy/PrivacyPolicy")
);
const TermsNConditions = lazy(() =>
  import("../../views/Terms&Conditions/TermsNConditions")
);
const ApplyJobs = lazy(() => import("../../views/ApplyJobs/applyJobsRb"));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackbar: false,
      snackbarVariant: "info",
      snackbarMessage: "",
    };
  }
  handleSnackbar = (
    showSnackbar = false,
    snackbarMessage = "",
    snackbarVariant = "info"
  ) => {
    this.setState({ showSnackbar, snackbarMessage, snackbarVariant });
  };

  render() {
    return (
      <Router history={history}>
        <Suspense
          fallback={
            <div>
              <LoadingPage></LoadingPage>
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Welcome {...props} handleSnackbar={this.handleSnackbar} />
              )}
            />
            <Route
              exact
              path="/home"
              render={(props) => (
                <HomePage {...props} handleSnackbar={this.handleSnackbar} />
              )}
            />
            <Route
              path="/about"
              render={(props) => (
                <About {...props} handleSnackbar={this.handleSnackbar} />
              )}
            />
            <Route
              path="/services"
              render={(props) => (
                <Services {...props} handleSnackbar={this.handleSnackbar} />
              )}
            />
            <Route
              path="/contactUs"
              render={(props) => (
                <ContactUs {...props} handleSnackbar={this.handleSnackbar} />
              )}
            />
            <Route
              path="/technology"
              render={(props) => (
                <Technology {...props} handleSnackbar={this.handleSnackbar} />
              )}
            />

            <Route
              path="/termsAndConditions"
              render={(props) => (
                <TermsNConditions
                  {...props}
                  handleSnackbar={this.handleSnackbar}
                />
              )}
            />
            <Route
              path="/privacyPolicy"
              render={(props) => (
                <PrivacyPolicy
                  {...props}
                  handleSnackbar={this.handleSnackbar}
                />
              )}
            />
            <Route
              path="/careers/:id"
              render={(props) => (
                <ApplyJobs {...props} handleSnackbar={this.handleSnackbar} />
              )}
            />
            <Route
              path="/careers"
              render={(props) => (
                <Careers {...props} handleSnackbar={this.handleSnackbar} />
              )}
            />
          </Switch>
        </Suspense>
        <MySnackbar
          open={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
        ></MySnackbar>
      </Router>
    );
  }
}
export default App;
