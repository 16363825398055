import React from "react";
import "../../assets/css/Home/LoadingIcon.css";
import LoadinGIcon from "../../assets/icons/Home/LoadingIcon";
class SimpleLoader extends React.Component {
  render() {
    return (
      <div className="simpleLoaderMain-div">
        <div className="loaderSimple-div">
          <LoadinGIcon></LoadinGIcon>
        </div>
      </div>
    );
  }
}
export default SimpleLoader;
